$light-blue: #f6fafd;
$danger: #bd2130;

// Small devices (landscape phones, 576px and up)
$sm: 576px;

// Medium devices (tablets, 768px and up)
$md: 768px;

// Large devices (desktops, 992px and up)
$lg: 992px;

// Extra large devices (large desktops, 1200px and up)
$xl: 1200px;

html,
body,
#root {
  height: 100%;
}

.min-100 {
  min-height: 100vh;
}

.landing-page {
  header {
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0px auto;
    width: 100vw;
    position: fixed;
    background: rgb(255, 255, 255);
    box-shadow: 0px 5px 10px #f6fafd;
    left: 0px;
    top: 0px;
    z-index: 100;
  }

  p {
    word-break: normal;
  }

  .logo {
    width: 36px;
    height: 36px;
  }

  .btn-purple {
    background-color: #4527a0;
    color: #fff;
    &:hover {
      background-color: #311b92;
      color: #fff;
    }
  }

  .btn-xxl {
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
  }

  .bg-light-blue {
    background-color: $light-blue;
  }

  .jumbotron {
    background-color: $light-blue;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .navbar-light .navbar-nav .nav-link {
    color: #000000;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .with-shadow {
    -moz-box-shadow: 0 10px 25px 0 rgba(32, 62, 84, 0.2);
    -webkit-box-shadow: 0 10px 25px 0 rgba(32, 62, 84, 0.2);
    box-shadow: 0 10px 25px 0 rgba(32, 62, 84, 0.2);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
  }

  .content-section {
    scroll-margin-top: 130px;
  }

  .content-footer {
    background-color: #f6fafd;
    border-radius: 0.3rem;
  }

  .hero {
    transition: background-image 0.75s linear;
  }

  .card-enrol {
    border-color: #4527a0;
    .card-header {
      border-color: #4527a0;
      background-color: #4527a0;
      color: #fff;
      margin-left: -1px;
    }
  }

  .custom-card-img {
    width: 100%;
    height: 16rem;
    background: #f6fafd;
    text-align: center;
    img {
      max-height: 100%;
      width: auto;
      margin: 0 auto;
    }
  }
  .list-group-item {
    background-color: transparent;
    padding-left: 0;
  }

  @media (max-width: $xl) {
    .hero {
      background: #f6fafd !important;
    }
  }

  @media (max-width: $md) {
    .logo {
      width: 46px;
      height: 46px;
    }
    .navbar-toggler {
      padding: 0.25rem 0.35rem;
      font-size: 0.8rem;
    }
    .custom-card-img {
      display: none;
    }
    .hero {
      h1 {
        font-size: 1.25rem;
      }
      .pr-5 {
        padding-right: 0.5rem !important;
      }
    }
    .jumbotron-form {
      padding: 0;
      background-color: #fff;
      .card-header {
        padding: 0.5rem;
      }
      .card-body {
        padding: 0.5rem;
        .btn-purple {
          width: 100%;
        }
      }
    }
    .footer {
      .navbar-text {
        font-size: 0.8rem;
      }
    }
  }

  &-pl,
  &-uk,
  &-ru {
    .jumbotron {
      background-position-y: 0;
      background-position-x: 175%;
    }
  }

  &-en {
    .jumbotron {
      background-position-y: 0;
      background-position-x: 160%;
    }
  }
}

.questionnaire-page {
  .blob {
    background: $danger;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-bottom: -3px;

    box-shadow: 0 0 0 0 rgba(114, 28, 36, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  .btn-notice {
    &:active {
      background-color: #fff !important;
      border-color: #007bff !important;
      color: #007bff !important;
      transition: none !important;
    }
  }

  .btn-notice-secondary {
    &:active {
      background-color: #fff !important;
      border-color: #6c757d !important;
      color: #6c757d !important;
      transition: none !important;
    }
  }

  .card-title b {
    text-decoration: underline;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(114, 28, 36, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(114, 28, 36, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(114, 28, 36, 0);
    }
  }

  .fade {
    transition: opacity 0.15s linear;
    opacity: 0;
    &.out {
      opacity: 0;
    }
    &.in {
      opacity: 1;
    }
  }
  .show {
    opacity: 1;
  }
}
